import { Stack } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { ApplicationState } from '../../../ApplicationState';
import { GlobalFlags } from '../../../GlobalFlags';
import { Api } from '../../../Server/Api';
import { openSystemBrowser } from '../../../Utilities';
import useStateFlags from '../../hooks/useStateFlags';
import EmailProviderActions from '../components/EmailProviderActions';
import EmailEngineQuotaReport from '../components/QuotaReport';
import { useEmailEngine } from '../provider/EmailEngineProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

const GmailProviderDetails = () => {
    const { devMode } = useStateFlags();
    const { navigateTo } = useEmailSettingsViewRouter();

    const {
        account,
        loadingAccount,
        reconnectAccount,
        reconnecting,
        disconnectAccount,
        disconnecting,
        syncAccount,
        syncing,
        registerAccount,
        emailProviders: { gmail },
    } = useEmailEngine();

    if (!gmail) return null;

    const handleDisconnectProvider = () => {
        navigateTo('email-settings');
        disconnectAccount();
    };

    const handleConnectClick = async () => {
        if (!gmail) return;
        if (gmail.connected) {
            reconnectAccount();
            return;
        }

        const host = GlobalFlags.isMobileApp ? 'web+squeegee:/' : Api.currentHostAndScheme;
        const path = '/email-settings';

        const squeegeeUrl = host + path;

        const redirectUrl = `${Api.currentHostAndScheme}/api/email-engine/connected-redirect?uuid=${
            ApplicationState.account.uuid
        }&squeegeeUrl=${encodeURIComponent(squeegeeUrl)}`;

        const authLink = await registerAccount({ type: gmail.type, redirectUrl });
        if (!authLink) return;

        if (GlobalFlags.isMobileApp) openSystemBrowser(authLink);
        else window.open(authLink, '_parent');
    };

    return (
        <Stack gap={1.5}>
            <EmailProviderActions
                provider={gmail}
                handleConnect={handleConnectClick}
                handleDisconnect={handleDisconnectProvider}
                handleSync={syncAccount}
                loading={loadingAccount || disconnecting || reconnecting || syncing}
            />
            {account?.quota && gmail.connected && <EmailEngineQuotaReport providerName={gmail.name} quota={account.quota} />}
            {devMode && (
                <DialogPaper sx={{ p: 2 }}>
                    <pre>{JSON.stringify(gmail, null, 2)}</pre>
                </DialogPaper>
            )}
        </Stack>
    );
};

export default GmailProviderDetails;
