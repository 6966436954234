import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { IconButton, Stack, Typography } from '@mui/material';
import { DialogLoader, DialogPaper } from '@nexdynamic/nex-ui-react';
import type { EmailEngineIMAPConfig, EmailEngineSMTPConfig } from '@nexdynamic/squeegee-common';
import type { EmailSettingsViewRoutes } from '../EmailSettingsViewRoutes';
import MailServerForm from '../components/MailServerForm';
import { useMailServer } from '../provider/MailServerProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

type Props = {
    parentRoot: EmailSettingsViewRoutes;
    protocol: 'smtp' | 'imap';
};

const MailServerConfig = ({ protocol }: Props) => {
    const { navigateBack } = useEmailSettingsViewRouter();
    const { mailServerSettings, setMailServerSettings, verifySmtpImapSettings, verifyingSmtpImapSettings, getDefaultPort, getDefaultTLS } =
        useMailServer();

    const otherProtocol = protocol === 'smtp' ? 'imap' : 'smtp';
    const initialEmailAddress = mailServerSettings[protocol]?.auth?.user || mailServerSettings[otherProtocol]?.auth?.user || '';
    const initialPassword = mailServerSettings[protocol]?.auth?.pass || mailServerSettings[otherProtocol]?.auth?.pass || '';

    const validateSettings = async (protocol: 'smtp' | 'imap', values: EmailEngineSMTPConfig | EmailEngineIMAPConfig) => {
        const mailServerSettings = { [protocol]: values };
        setMailServerSettings(mailServerSettings);
        const success = await verifySmtpImapSettings(mailServerSettings);
        if (success) return navigateBack();
    };

    return (
        <>
            <DialogPaper sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                    <IconButton onClick={() => navigateBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">{protocol.toUpperCase()} Configuration</Typography>
                </Stack>
                <MailServerForm
                    protocol={protocol}
                    onValidate={validateSettings}
                    initialValues={{
                        emailAddress: initialEmailAddress,
                        appPassword: initialPassword,
                        serverAddress: mailServerSettings[protocol]?.host || '',
                        port: mailServerSettings[protocol]?.port || getDefaultPort(0, initialEmailAddress, protocol),
                        useTLS: mailServerSettings[protocol]?.secure || getDefaultTLS(undefined, initialEmailAddress, protocol),
                    }}
                />
            </DialogPaper>
            <DialogLoader open={verifyingSmtpImapSettings} message="Verifying SMTP/IMAP settings..." />
        </>
    );
};

export default MailServerConfig;
