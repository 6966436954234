import EmailIcon from '@mui/icons-material/EmailRounded';
import LanguageIcon from '@mui/icons-material/LanguageRounded';
import LockIcon from '@mui/icons-material/LockRounded';
import PortIcon from '@mui/icons-material/SettingsEthernetRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDebounce } from '@nexdynamic/nex-ui-react';
import type { EmailEngineIMAPConfig, EmailEngineSMTPConfig } from '@nexdynamic/squeegee-common';
import React, { useEffect, useState } from 'react';
import { useMailServer } from '../provider/MailServerProvider';
import HelperText from './HelperText';

type MailServerSettings = {
    emailAddress: string;
    appPassword: string;
    serverAddress: string;
    port: number;
    useTLS: boolean;
};

interface MailServerFormProps {
    protocol: 'smtp' | 'imap';
    initialValues: MailServerSettings;
    onValidate?: (protocol: 'smtp' | 'imap', values: EmailEngineSMTPConfig | EmailEngineIMAPConfig) => void;
    setExternalValues?: (values: EmailEngineSMTPConfig | EmailEngineIMAPConfig) => void;
}

const MailServerForm: React.FC<MailServerFormProps> = ({ protocol, onValidate, initialValues, setExternalValues }) => {
    const { cacheMxService, getDefaultHost, getDefaultPort, getDefaultTLS, configErrors, removeConfigError, mailServerErrors } =
        useMailServer();

    const [formValues, setValues] = React.useState<MailServerSettings>({ ...initialValues });

    useEffect(() => {
        if (setExternalValues)
            setExternalValues({
                auth: {
                    user: formValues.emailAddress,
                    pass: formValues.appPassword,
                    accessToken: false,
                },
                host: formValues.serverAddress,
                port: formValues.port,
                secure: formValues.useTLS,
            });
    }, [formValues]);

    const [showPassword, setShowPassword] = useState(false);

    const handleChange =
        (field: keyof MailServerSettings, key: 'user' | 'pass' | 'host' | 'port' | 'secure') =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = field === 'useTLS' ? event.target.checked : event.target.value;
            setValues({
                ...formValues,
                [field]: value,
            });
            removeConfigError(key);

            if (setExternalValues) {
                setExternalValues({
                    auth: {
                        user: formValues.emailAddress,
                        pass: formValues.appPassword,
                        accessToken: false,
                    },
                    host: formValues.serverAddress,
                    port: formValues.port,
                    secure: formValues.useTLS,
                });
            }
        };

    const handleValidate = () => {
        onValidate?.(protocol, {
            auth: {
                user: formValues.emailAddress,
                pass: formValues.appPassword,
                accessToken: false,
            },
            host: formValues.serverAddress,
            port: formValues.port,
            secure: formValues.useTLS,
        });
    };

    const debouncedEmailAddress = useDebounce(formValues.emailAddress, 500);

    useEffect(() => {
        cacheMxService(formValues.emailAddress).then(serviceProvider => {
            const defaultHost = getDefaultHost(formValues.serverAddress, serviceProvider || formValues.emailAddress, protocol);
            const defaultPort = getDefaultPort(formValues.port, serviceProvider || formValues.emailAddress, protocol);
            const defaultTLS = getDefaultTLS(formValues.useTLS, serviceProvider || formValues.emailAddress, protocol);

            const newValues = {
                ...formValues,
                serverAddress: defaultHost,
                port: defaultPort,
                useTLS: defaultTLS,
            };

            setValues(newValues);
        });
    }, [debouncedEmailAddress]);

    return (
        <Grid container spacing={2}>
            {/* User Credentials Section */}
            <Grid item xs={12}>
                <Box mb={1}>
                    <Typography variant="subtitle1">User Credentials</Typography>
                </Box>
            </Grid>

            {/* Email Address Field */}
            <Grid item xs={12}>
                <TextField
                    label="Username"
                    fullWidth
                    value={formValues.emailAddress}
                    onChange={handleChange('emailAddress', 'user')}
                    required
                    placeholder="user@example.com"
                    helperText={
                        <HelperText
                            helperText={<Typography variant="caption">This is the email address emails are sent from.</Typography>}
                            configErrors={configErrors}
                            property="user"
                        />
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            {/* App Password Field */}
            <Grid item xs={12}>
                <TextField
                    label="App Password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={formValues.appPassword}
                    onChange={handleChange('appPassword', 'pass')}
                    required
                    placeholder="••••••••"
                    helperText={
                        <HelperText
                            helperText={
                                <>
                                    Use an app password for enhanced security.{' '}
                                    <Link
                                        href="https://support.google.com/accounts/answer/185833"
                                        target="_blank"
                                        rel="noopener"
                                        sx={{ fontWeight: 500 }}
                                    >
                                        Instructions
                                    </Link>
                                </>
                            }
                            configErrors={configErrors}
                            property="pass"
                        />
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            {/* Server Details Section */}
            <Grid item xs={12}>
                <Box mt={2}>
                    <Typography variant="subtitle1">Server Details</Typography>
                </Box>
            </Grid>

            {/* Server Address Field */}
            <Grid item xs={12}>
                <TextField
                    label={`${protocol.toUpperCase()} Server`}
                    fullWidth
                    value={formValues.serverAddress}
                    onChange={handleChange('serverAddress', 'host')}
                    required
                    placeholder={`your.${protocol}.host.com`}
                    helperText={
                        <HelperText
                            helperText={<Typography variant="caption">This is the hostname or IP address of your server.</Typography>}
                            configErrors={configErrors}
                            property="host"
                        />
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LanguageIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Port"
                    type="number"
                    fullWidth
                    value={formValues.port}
                    onChange={e => {
                        setValues({ ...formValues, port: parseInt(e.target.value) });
                        removeConfigError('port');
                    }}
                    required
                    placeholder={protocol === 'smtp' ? '587' : '993'}
                    helperText={
                        <HelperText
                            helperText={
                                protocol === 'smtp' ? (
                                    <Typography variant="caption">Port used by your SMTP server. Common ports: 465, 587.</Typography>
                                ) : (
                                    <Typography variant="caption">Port used by your IMAP server. Common ports: 993, 143.</Typography>
                                )
                            }
                            configErrors={configErrors}
                            property="port"
                        />
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PortIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={formValues.useTLS} onChange={handleChange('useTLS', 'secure')} color="primary" />}
                    label="Use TLS"
                />
            </Grid>

            <Grid item xs={12}>
                {mailServerErrors?.smtp && (
                    <Typography color="error" fontWeight="bold">
                        {mailServerErrors.smtp}
                    </Typography>
                )}
                {mailServerErrors?.imap && (
                    <Typography color="error" fontWeight="bold">
                        {mailServerErrors.imap}
                    </Typography>
                )}
            </Grid>

            {onValidate && (
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} mt={2}>
                        <Button type="submit" color="primary" fullWidth onClick={handleValidate}>
                            Save
                        </Button>
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};

export default MailServerForm;
