import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import MissingIcon from '@mui/icons-material/HelpRounded';
import LinkOffIcon from '@mui/icons-material/LinkOffRounded';
import SyncIcon from '@mui/icons-material/SyncRounded';
import {
    Button,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import type { EmailEngineIMAPConfig, EmailEngineSMTPConfig } from '@nexdynamic/squeegee-common';
import useTranslation from '../../hooks/useTranslation';
import ActionableListItem from '../components/ActionableListItem';
import EmailEngineQuotaReport from '../components/QuotaReport';
import { useEmailEngine } from '../provider/EmailEngineProvider';
import { useMailServer } from '../provider/MailServerProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

export type SmtpImapSettings = {
    smtp?: EmailEngineSMTPConfig;
    imap?: EmailEngineIMAPConfig;
};

const CustomEmailProviderDetails = () => {
    const { t } = useTranslation();
    const { navigateTo } = useEmailSettingsViewRouter();

    const { mailServerSettings } = useMailServer();

    const {
        account,
        loadingAccount,
        reconnecting,
        disconnectAccount,
        disconnecting,
        syncAccount: handleSync,
        syncing,
        emailProviders: { custom },
    } = useEmailEngine();

    if (!custom) return null;

    const loading = loadingAccount || reconnecting || disconnecting || syncing;

    const handleDisconnectProvider = async () => {
        await disconnectAccount();
        navigateTo('email-settings');
    };

    const getStatusIcon = (protocol: 'smtp' | 'imap') => {
        if (loading) return <CircularProgress size={20} />;
        if (custom.connected) return <CheckIcon sx={{ color: 'success.main' }} />;
        else if (mailServerSettings[protocol]?.valid !== undefined)
            return mailServerSettings[protocol]?.valid ? (
                <CheckIcon sx={{ color: 'success.main' }} />
            ) : (
                <ErrorIcon sx={{ color: 'warning.main' }} />
            );
        else if (!mailServerSettings[protocol]) return <MissingIcon sx={{ color: 'action.disabled' }} />;
        return <ErrorIcon sx={{ color: 'warning.main' }} />;
    };

    return (
        <Stack gap={1.5}>
            <DialogPaper sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                    <IconButton onClick={() => navigateTo('email-settings')}>
                        <ArrowBackIcon />
                    </IconButton>

                    <Typography variant="h6" gutterBottom>
                        {t('email-settings.connection-details')}
                    </Typography>
                </Stack>

                <ActionableListItem
                    primary={`${t(custom.name)} ${t('general.account')}`}
                    secondary={t('email-api.email-engine', { provider: custom.connection?.auth.user || '?' })}
                    slots={{
                        secondaryArea: (
                            <Chip
                                label={custom.connected ? t('general.connected') : t('general.not-connected')}
                                color={custom.connected ? 'success' : 'default'}
                                size="small"
                            />
                        ),
                        icon: custom.icon,
                    }}
                    // WTF: Grouping the list item with the sync & disconnect buttons for a flowy UI.
                    slotProps={{ paper: { sx: { backgroundImage: 'none', backgroundColor: 'transparent' }, elevation: 0 } }}
                />

                {custom.connected && (
                    <Stack direction="row" spacing={1} sx={{ m: 2, mt: 1 }}>
                        <Button onClick={handleSync} variant="outlined" color="success" endIcon={<SyncIcon />} fullWidth disabled={loading}>
                            {t('email-settings.request-sync')}
                        </Button>

                        <Button
                            onClick={handleDisconnectProvider}
                            variant="outlined"
                            color="error"
                            endIcon={<LinkOffIcon />}
                            fullWidth
                            disabled={loading}
                        >
                            {t('email-settings.disconnect-account')}
                        </Button>
                    </Stack>
                )}
                <Divider sx={{ my: 1 }} />
                <List disablePadding>
                    {custom.connected ? (
                        <>
                            <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                                <ListItemButton onClick={() => navigateTo('custom-provider/smtp')}>
                                    <ListItemText primary={t('email-settings.smtp-configuration-title')} />
                                    {getStatusIcon('smtp')}
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                                <ListItemButton onClick={() => navigateTo('custom-provider/imap')}>
                                    <ListItemText primary={t('email-settings.imap-configuration-title')} />
                                    {getStatusIcon('imap')}
                                </ListItemButton>
                            </ListItem>
                        </>
                    ) : (
                        <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                            <ListItemButton onClick={() => navigateTo('custom-provider/setup-wizard')}>
                                <ListItemText
                                    primary={t('email-settings.set-up-your-email-title')}
                                    secondary={t('email-settings.set-up-your-email-description')}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </DialogPaper>

            {account?.quota && custom.connected && <EmailEngineQuotaReport providerName={custom.name} quota={account.quota} />}
        </Stack>
    );
};

export default CustomEmailProviderDetails;
