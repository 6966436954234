import { Box, Grid, Paper, Typography } from '@mui/material';
import type { EditorTemplate } from '@nexdynamic/send-editor/core';
import type { TemplateState } from '@nexdynamic/squeegee-common';
import React, { useState } from 'react';
import { useSetting } from '../../../../hooks/useSetting';
import { TemplateEditor } from '../components/TemplateEditor';
import { BlockTemplatePreview, ChooseStartingTemplate } from './ChooseStartingTemplate';

type Props = {
    onSubmit: (state: TemplateState | EditorTemplate, openEditor?: boolean) => Promise<void>;
    initialTemplateState?: TemplateState;
    initialEditorTemplate?: EditorTemplate;
};

export const TemplateFormEditorStep = React.forwardRef<HTMLFormElement, Props>(
    ({ initialTemplateState, initialEditorTemplate, onSubmit }, ref) => {
        const [blockEditorEnabled] = useSetting('beta-features.block-editor', false);
        const [selectedTemplate, setSelectedTemplate] = useState<EditorTemplate | undefined>(initialEditorTemplate);
        const [templateState, setTemplateState] = useState<TemplateState | undefined>(initialTemplateState);

        const [showSelectError, setShowSelectError] = useState(false);

        const finish = async (openEditor?: boolean) => {
            if (blockEditorEnabled && selectedTemplate) {
                await onSubmit(selectedTemplate, openEditor);
                return;
            } else if (templateState) {
                await onSubmit(templateState || selectedTemplate);
                return;
            }

            setShowSelectError(true);
        };

        return (
            <Grid item xs={12}>
                <Paper>
                    <Box
                        sx={{ p: 2 }}
                        component="form"
                        ref={ref}
                        onSubmit={event => {
                            event.preventDefault();
                            finish();
                        }}
                    >
                        {blockEditorEnabled || initialEditorTemplate ? (
                            <Box>
                                <Typography variant="h6">Editor</Typography>
                                <Typography variant="subtitle1" mb={2}>
                                    Customise your template using the squeegee template editor
                                </Typography>
                                <Box>
                                    {initialEditorTemplate ? (
                                        <Box sx={{ p: 2, backgroundColor: 'background.default', borderRadius: 1 }}>
                                            <BlockTemplatePreview
                                                selectText="Open In Editor"
                                                template={initialEditorTemplate}
                                                onClick={() => finish(true)}
                                            />
                                        </Box>
                                    ) : (
                                        <>
                                            <ChooseStartingTemplate value={selectedTemplate} onSelect={setSelectedTemplate} />

                                            {showSelectError ? (
                                                <Typography color="error" variant="subtitle1" mt={2} mb={1}>
                                                    Please select a starting template to continue
                                                </Typography>
                                            ) : null}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        ) : (
                            <TemplateEditor initialValue={templateState} onChange={setTemplateState} />
                        )}
                    </Box>
                </Paper>
            </Grid>
        );
    }
);
