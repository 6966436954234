import { Box } from '@mui/material';
import type { EditorTemplate } from '@nexdynamic/send-editor/core';
import type { EditorMediaItem, EditorMediaQuery } from '@nexdynamic/send-editor/editor';
import { Editor, EditorMediaProvider, EditorProvider } from '@nexdynamic/send-editor/editor';
import type { Attachment, BlockTemplate } from '@nexdynamic/squeegee-common';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationState } from '../../ApplicationState';
import { AttachmentService } from '../../Attachments/AttachmentService';
import { AttachmentUploadItem } from '../../Attachments/AttachmentUploadItem';
import { AttachmentDetailsDialog } from '../../Attachments/Dialogs/AttachmentDetailsDialog';
import { Data } from '../../Data/Data';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import useStateFlags from '../hooks/useStateFlags';
import useStoredObject from '../hooks/useStoredObject';
import useStoredObjects from '../hooks/useStoredObjects';
import useUpdateTemplate from '../send/features/templates/hooks/useUpdateTemplate';
import './EditorView.css';

type EditorAttachmentQuery = {
    take: number;
    skip: number;
    search?: string;
};

const getAttachmentsAsMediaItems = (query: EditorAttachmentQuery) => {
    const attachments = Data.all<Attachment>('attachments', item => {
        let include = true;
        if (item.mimeType.includes('image') === false) include = false;
        if (item.removedFromStorage) include = false;
        if (item.isPublic !== true) include = false;
        if (query.search?.trim() && item.name.localeCompare(query.search) === -1) include = false;
        return include;
    })
        .slice(0)
        .sort((a, b) => b.uploadDate - a.uploadDate)
        .slice(query.skip, query.take - 1);

    const mediaItems = attachments.map(attachment => {
        const url = AttachmentService.getPublicUrl(attachment);
        return {
            name: attachment.name,
            url,
            thumbnailUrl: url, // TODO - get thumbnail
            mimeType: attachment.mimeType,
            dimensions: {
                width: attachment.dimensions?.x || 0,
                height: attachment.dimensions?.y || 0,
            },
            fileId: attachment._id,
            size: attachment.size,
            alt: attachment.name,
            attribution: '',
            attributionLink: '',
        } satisfies EditorMediaItem;
    });

    return {
        items: mediaItems,
        total: attachments.length,
    };
};

export const EditorView = () => {
    const { id } = useParams();
    const { updateTemplate } = useUpdateTemplate(id as string);
    const { devMode } = useStateFlags();
    const [, setUploads] = useState(0); // State to track uploads
    const template = useStoredObject<BlockTemplate>(id);
    const attachments = useStoredObjects<Attachment>('attachments');

    const api = useMemo(() => {
        return {
            onUpload: async (files: FileList): Promise<void> => {
                const items: Promise<void>[] = [];

                for (const file of files) {
                    items.push(new AttachmentUploadItem('NEW', file, undefined, undefined, undefined, undefined, true).upload());
                }

                await Promise.all(items);
                setUploads(prev => prev + 1);
            },
            onView: async (item: EditorMediaItem): Promise<void> => {
                const dialog = new AttachmentDetailsDialog(item.fileId);
                await dialog.show();
            },
            onQuery: async (
                query: EditorMediaQuery
            ): Promise<{
                items: EditorMediaItem[];
                total: number;
            }> => {
                return new Promise(resolve => {
                    setTimeout(() => {
                        const result = getAttachmentsAsMediaItems(query);
                        resolve(result);
                    }, 80);
                });
            },
        };
    }, []);

    if (!template) return <Box>Template not found</Box>;

    const saveTemplate = async (template: EditorTemplate) => {
        await updateTemplate(template);
    };

    const close = async () => {
        ApplicationState.navigateToRouteFragment('/send/templates');
    };

    return (
        <EditorProvider
            session={RethinkDbAuthClient.getSessionKeyAndValue()}
            isDev={devMode}
            onSave={fields => saveTemplate(fields)}
            onClose={close}
            template={template}
        >
            <EditorMediaProvider {...api} mediaCount={attachments.length}>
                <Editor />
            </EditorMediaProvider>
        </EditorProvider>
    );
};
