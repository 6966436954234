import { List } from '@mui/material';
import useStateFlags from '../../hooks/useStateFlags';
import ActionableListItem from './ActionableListItem';

const EmailProvidersSkeleton = () => {
    const { devMode } = useStateFlags();
    return (
        <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 2 }}>
            <ActionableListItem skeleton />
            <ActionableListItem skeleton />
            {devMode && <ActionableListItem skeleton />}
        </List>
    );
};

export default EmailProvidersSkeleton;
