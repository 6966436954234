import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, Input, List, ListItem, ListItemSecondaryAction, Stack, Typography } from '@mui/material';
import { DialogPaper, useDebounce } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { NotifyUserMessage } from '../../../Notifications/NotifyUserMessage';
import { validateEmailList } from '../../../Utilities';
import { t } from '../../../t';
import { useSetting } from '../../hooks/useSetting';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

export const Blocklist = () => {
    const { navigateBack } = useEmailSettingsViewRouter();
    const [ignoredEmails, setIgnoredEmails] = useSetting<Array<string>>('global.ignored-emails', []);
    const [emailEntered, setEmailEntered] = useState('');
    const value = useDebounce(emailEntered, 150);
    const [editingEmail, setEditingEmail] = useState<string | null>(null);
    const filter = (text: string) => {
        if (!text) return ignoredEmails;

        return ignoredEmails.filter(email => email.includes(text));
    };

    const [filteredEmails, setFilteredEmails] = useState<Array<string>>(ignoredEmails);

    const handleSearch = (text: string) => setFilteredEmails(filter(text));

    const removeFromBlocklist = (emailToRemove: string) => {
        if (ignoredEmails.length === 0) return;

        const newBlocklist = ignoredEmails.filter(email => email !== emailToRemove);

        console.log(newBlocklist);
        setIgnoredEmails(newBlocklist);
        setFilteredEmails(newBlocklist);
    };

    const addToBlocklist = (emailToAdd: string) => {
        if (value.length < 3) return new NotifyUserMessage('validation.valid-email-required');
        if (ignoredEmails.includes(emailToAdd)) return new NotifyUserMessage('email-settings.email-already-blocked');
        const valid = validateEmailList(emailToAdd);
        if (typeof valid === 'string') return new NotifyUserMessage(valid);

        const newBlocklist = [...ignoredEmails, emailToAdd];

        ApplicationState.setSetting('global.ignored-emails', newBlocklist);
        setIgnoredEmails(newBlocklist);
        setFilteredEmails(newBlocklist);
    };

    const edit = (email: string) => {
        console.log(email);
        setEditingEmail(email);

        if (editingEmail === email) {
            setEditingEmail(null);
        }
    };

    const saveEdit = (newEmail: string) => {
        const newBlocklist = ignoredEmails.map(email => (email === editingEmail ? newEmail : email));
        console.log(newBlocklist);
        setIgnoredEmails(newBlocklist);
        setFilteredEmails(newBlocklist);
    };
    const EditingView = ({ email }: { email: string }) => {
        const [localEmail, setLocalEmail] = useState(email);

        const handleSave = () => {
            saveEdit(localEmail);
            setEditingEmail(null);
        };

        if (editingEmail !== email) return <Typography>{email}</Typography>;

        return (
            <Stack direction="row" alignItems="center" width={'100%'}>
                <Input
                    value={localEmail}
                    onChange={e => setLocalEmail(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleSave();
                        }
                    }}
                    onBlur={handleSave}
                    fullWidth
                    //eslint-disable-next-line
                    autoFocus={true}
                />
                <Button onClick={() => setEditingEmail(null)}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </Stack>
        );
    };

    return (
        <Stack gap={2}>
            <DialogPaper sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => navigateBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>
                        Blocklist
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" padding={2}>
                    <Input
                        placeholder={t('email-settings.add-email-to-blocklist')}
                        fullWidth
                        value={emailEntered}
                        onChange={e => {
                            setEmailEntered(e.target.value);
                            handleSearch(e.target.value);
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                addToBlocklist(value);
                                setEmailEntered('');
                                filter('');
                            }
                        }}
                    />
                    <Button
                        onClick={() => {
                            addToBlocklist(value);
                            setEmailEntered('');
                        }}
                    >
                        Add
                    </Button>
                </Stack>
            </DialogPaper>
            {filteredEmails.length > 0 && (
                <DialogPaper>
                    <List>
                        {filteredEmails.map(email => (
                            <ListItem key={email}>
                                <EditingView email={email} />
                                {editingEmail !== email && (
                                    <ListItemSecondaryAction>
                                        <Stack direction="row">
                                            <Button onClick={() => edit(email)}>Edit</Button>
                                            <Button onClick={() => removeFromBlocklist(email)}>Remove</Button>
                                        </Stack>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </DialogPaper>
            )}
        </Stack>
    );
};
