import MailServerForm from '../../../components/MailServerForm';
import { useMailServer } from '../../../provider/MailServerProvider';

const MailServerStep = ({ protocol, setExternalValues }: { protocol: 'smtp' | 'imap'; setExternalValues?: (values: any) => void }) => {
    const { mailServerSettings, getDefaultPort, getDefaultTLS } = useMailServer();
    const otherProtocol = protocol === 'smtp' ? 'imap' : 'smtp';
    const initialEmailAddress = mailServerSettings[protocol]?.auth?.user || mailServerSettings[otherProtocol]?.auth?.user || '';
    const initialPassword = mailServerSettings[protocol]?.auth?.pass || mailServerSettings[otherProtocol]?.auth?.pass || '';

    return (
        <MailServerForm
            protocol={protocol}
            initialValues={{
                emailAddress: initialEmailAddress,
                appPassword: initialPassword,
                serverAddress: mailServerSettings[protocol]?.host || '',
                port: mailServerSettings[protocol]?.port || getDefaultPort(0, initialEmailAddress, protocol),
                useTLS: mailServerSettings[protocol]?.secure || getDefaultTLS(undefined, initialEmailAddress, protocol),
            }}
            setExternalValues={setExternalValues}
        />
    );
};

export default MailServerStep;
