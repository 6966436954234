import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ConnectivityIcon from '@mui/icons-material/CircleRounded';
import { List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { prompt } from '../../../Dialogs/ReactDialogProvider';
import { SmtpDialog } from '../../../Dialogs/SmtpDialog';
import { Api } from '../../../Server/Api';
import useApplicationState from '../../hooks/useApplicationState';
import { useSqueegeeCredits } from '../../hooks/useSqueegeeCredits';
import useStateFlags from '../../hooks/useStateFlags';
import useTranslation from '../../hooks/useTranslation';
import ActionableListItem from '../components/ActionableListItem';
import EmailProvidersSkeleton from '../components/EmailProvidersSkeleton';
import { useEmailEngine, type EmailProvider } from '../provider/EmailEngineProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

const EmailSettingsMain = () => {
    const { t } = useTranslation();
    const { devMode } = useStateFlags();
    const { navigateTo } = useEmailSettingsViewRouter();

    const { credits, loading: loadingCredits, inboundEmailCreditsCharge } = useSqueegeeCredits();
    const { account: emailEngineAccount, loadingAccount, emailProviders, disconnectAccount } = useEmailEngine();
    const { account } = useApplicationState();

    const [oneWayEnabled, setOneWayEnabled] = useState(account.emailAPI === 'squeegee-smtp');

    const handleProviderClick = async (provider: EmailProvider) => {
        if (provider.oneWay === false) {
            provider.route && navigateTo(provider.route);
            return;
        }
    };

    const handleSwitchChange = async () => {
        if (oneWayEnabled) return;
        const areYouSurePrompt = prompt('prompts.disconnect-two-way-email-title', 'prompts.disconnect-two-way-email-description', {
            okLabel: 'general.disconnect',
            cancelLabel: 'general.cancel',
        });

        const sure = await areYouSurePrompt.show();
        if (sure) {
            await disconnectAccount();
            setOneWayEnabled(true);
        }
    };

    const openLegacySmtpSettingsDialog = async () => {
        const smtpDialog = new SmtpDialog();
        smtpDialog.show();
    };

    if (!emailProviders) return null;

    const username = Object.values(emailProviders).find(x => x.connected)?.connection?.auth.user || 'unknown';
    return (
        <Stack gap={2}>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {t('email-settings.email-providers-title')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('email-settings.current-configuration')}
                    {': '}
                    {t(`email-api.${account.emailAPI || 'squeegee-smtp'}`, { provider: username })}
                </Typography>
                {loadingAccount ? (
                    <EmailProvidersSkeleton />
                ) : (
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 2 }}>
                        {Object.values(emailProviders).map(provider => {
                            if (
                                provider.connected ||
                                provider.oneWay === true ||
                                ((!provider.devOnly || (provider.devOnly && devMode)) && ApplicationState.features.twoWayEmail)
                            ) {
                                let connected = false;
                                if (provider.oneWay && (account.emailAPI || 'squeegee-smtp') === 'squeegee-smtp') connected = true;
                                else if (!provider.oneWay && provider.connected) connected = true;

                                return (
                                    <ActionableListItem
                                        key={provider.name}
                                        primary={
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                {provider.oneWay === false && (
                                                    <ConnectivityIcon color={connected ? 'success' : 'disabled'} sx={{ fontSize: 10 }} />
                                                )}
                                                <Typography fontSize={16}>{t(provider.name)}</Typography>
                                            </Stack>
                                        }
                                        secondary={t(provider.description)}
                                        onClick={!provider.oneWay ? () => handleProviderClick(provider) : undefined}
                                        slots={{
                                            secondaryAction: provider.oneWay ? (
                                                <Switch
                                                    disabled={provider.connected}
                                                    checked={provider.connected}
                                                    onChange={handleSwitchChange}
                                                />
                                            ) : (
                                                <ChevronRightIcon />
                                            ),
                                            icon: provider.icon,
                                        }}
                                        slotProps={{ avatarProps: { color: 'info' } }}
                                        disabled={!Api.isConnected}
                                    />
                                );
                            }

                            return null;
                        })}
                    </List>
                )}
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Typography variant="caption">
                        {t('email-settings.inbound-charge', { charge: inboundEmailCreditsCharge.toString() })}
                    </Typography>
                    <Typography variant="caption" fontWeight="bold">
                        {loadingCredits && !credits ? <Skeleton /> : credits.toLocaleString()} credits
                    </Typography>
                </Stack>
            </DialogPaper>
            <DialogPaper>
                <List disablePadding>
                    <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                        <ListItemButton onClick={() => navigateTo('blocklist')}>
                            <ListItemText
                                primary={t('email-settings.ignored-emails')}
                                secondary={t('email-settings.ignored-emails-subtitle')}
                            />
                        </ListItemButton>
                    </ListItem>
                    {(devMode || account.emailAPI === 'custom-smtp') && (
                        <>
                            <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                                <ListItemButton onClick={openLegacySmtpSettingsDialog}>
                                    <ListItemText
                                        primary={t('email-settings.legacy-smtp-title')}
                                        secondary={t('email-settings.legacy-smtp-description')}
                                    />
                                </ListItemButton>
                            </ListItem>
                            {devMode && emailEngineAccount?.webhooks && (
                                <ListItem>
                                    <ListItemText primary="Webhook Server" secondary={emailEngineAccount.webhooks} />
                                </ListItem>
                            )}
                        </>
                    )}
                </List>
            </DialogPaper>
        </Stack>
    );
};

export default EmailSettingsMain;
