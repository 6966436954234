import { Container } from '@mui/material';
import { DialogRoot, ViewRenderer, ViewRouter } from '@nexdynamic/nex-ui-react';
import { useEffect, useMemo } from 'react';
import { ApplicationState } from '../../ApplicationState';
import { createViewRouter } from './createViewRouter';
import { EmailEngineProvider } from './provider/EmailEngineProvider';
import { MailServerProvider } from './provider/MailServerProvider';
import { Blocklist } from './views/Blocklist';
import CustomEmailProviderDetails from './views/CustomEmailProviderDetails';
import EmailSettingsMain from './views/EmailSettingsMain';
import GmailProviderDetails from './views/GmailProviderDetails';
import MailServerConfig from './views/MailServerConfig';
import MailServerSetupWizard from './views/MailServerWizard/MailServerSetupWizard';

export type EmailSettingsDialogView = 'root' | 'provider' | 'blocklist';
const EmailSettingsDialog = () => {
    const viewRoutes = useMemo(() => {
        return createViewRouter({
            'email-settings': () => <EmailSettingsMain />,
            'custom-provider': () => <CustomEmailProviderDetails />,
            'custom-provider/setup-wizard': () => <MailServerSetupWizard />,
            'custom-provider/smtp': () => <MailServerConfig parentRoot="custom-provider" protocol="smtp" />,
            'custom-provider/imap': () => <MailServerConfig parentRoot="custom-provider" protocol="imap" />,
            'gmail': () => <GmailProviderDetails />,
            'blocklist': () => <Blocklist />,
        } as const);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const account = queryParams.get('account');

        if (account) {
            ApplicationState.account.emailAPI = 'email-engine';
            ApplicationState.save();
        }
    }, []);

    return (
        <ViewRouter initialView="email-settings">
            <EmailEngineProvider>
                <MailServerProvider>
                    <Container maxWidth="lg" disableGutters>
                        <DialogRoot>
                            <ViewRenderer views={viewRoutes} />
                        </DialogRoot>
                    </Container>
                </MailServerProvider>
            </EmailEngineProvider>
        </ViewRouter>
    );
};

export default EmailSettingsDialog;
