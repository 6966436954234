import type {
    EmailEngineAccountVerificationResponse,
    EmailEngineIMAPConfig,
    EmailEngineRequestError,
    EmailEngineSMTPConfig,
} from '@nexdynamic/squeegee-common';
import { copyObject } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import type React from 'react';
import { createContext, useContext, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { Logger } from '../../../Logger';
import { Api } from '../../../Server/Api';
import { getMxService } from '../utils/getMxService';
import { useEmailEngine } from './EmailEngineProvider';

export type SmtpImapSettings = {
    smtp?: EmailEngineSMTPConfig & { valid?: boolean };
    imap?: EmailEngineIMAPConfig & { valid?: boolean };
};

type MailServerContextType = {
    cacheMxService: (username: string) => Promise<'google' | 'outlook' | undefined>;
    configErrors: Array<{ message: string; key: string }>;
    removeConfigError: (key: string) => void;
    mailServerErrors: { smtp?: string; imap?: string } | undefined;
    mailServerSettings: SmtpImapSettings;
    setMailServerSettings: React.Dispatch<React.SetStateAction<SmtpImapSettings>>;
    verifySmtpImapSettings: ({ smtp, imap }: { smtp?: EmailEngineSMTPConfig; imap?: EmailEngineIMAPConfig }) => Promise<boolean>;
    verifyingSmtpImapSettings: boolean;
    getDefaultHost: (current: string, username: string, protocol: 'smtp' | 'imap') => string;
    getDefaultPort: (current: number, username: string, protocol: 'smtp' | 'imap') => number;
    getDefaultTLS: (current: boolean | undefined, username: string, protocol: 'smtp' | 'imap') => boolean;
};

const MailServerContext = createContext<MailServerContextType | undefined>(undefined);

export const MailServerProvider = ({ children }: { children: React.ReactNode }) => {
    const [configErrors, setConfigErrors] = useState<Array<{ message: string; key: string }>>([]);
    const [mailServerErrors, setMailServerErrors] = useState<{ smtp?: string; imap?: string } | undefined>();
    const [mailServerSettings, setMailServerSettings] = useState<SmtpImapSettings>({});
    const [verifyingSmtpImapSettings, setVerifyingSmtpImapSettings] = useState(false);
    const { getAccount } = useEmailEngine();

    const cacheMxService = async (username: string): Promise<'google' | 'outlook' | undefined> => {
        const parts = username.split('@');
        if (parts.length !== 2 || parts[1].length < 5 || !parts[1].includes('.') || parts[1].split('.').some(x => !x.trim())) return;

        const service = await getMxService(parts[1]);
        return service;
    };

    const getDefaultHost = (
        current: string,
        usernameOrServiceProvider: string | 'google' | 'outlook',
        protocol: 'smtp' | 'imap'
    ): string => {
        if (/.*?@yahoo\.com$/.test(usernameOrServiceProvider) || /.*?@yahoo\.co.uk$/.test(usernameOrServiceProvider))
            return protocol === 'smtp' ? 'smtp.mail.yahoo.com' : 'imap.mail.yahoo.com';
        if (
            usernameOrServiceProvider === 'google' ||
            /.*?@gmail\.com$/.test(usernameOrServiceProvider) ||
            /.*?@googlemail\.com$/.test(usernameOrServiceProvider)
        )
            return protocol === 'smtp' ? 'smtp.gmail.com' : 'imap.gmail.com';
        if (
            usernameOrServiceProvider === 'outlook' ||
            /.*?@outlook\.com$/.test(usernameOrServiceProvider) ||
            /.*?@hotmail\.com$/.test(usernameOrServiceProvider)
        )
            return protocol === 'smtp' ? 'smtp.office365.com' : 'outlook.office365.com';
        if (/.*?@btinternet\.com$/.test(usernameOrServiceProvider)) return 'mail.btinternet.com';
        return current;
    };

    const getDefaultPort = (
        current: number,
        usernameOrServiceProvider: string | 'google' | 'outlook',
        protocol: 'smtp' | 'imap'
    ): number => {
        if (/.*?@yahoo\.com$/.test(usernameOrServiceProvider) || /.*?@yahoo\.co.uk$/.test(usernameOrServiceProvider))
            return protocol === 'smtp' ? 465 : 993;
        if (
            usernameOrServiceProvider === 'google' ||
            /.*?@gmail\.com$/.test(usernameOrServiceProvider) ||
            /.*?@googlemail\.com$/.test(usernameOrServiceProvider)
        )
            return protocol === 'smtp' ? 587 : 993;
        if (
            usernameOrServiceProvider === 'outlook' ||
            /.*?@outlook\.com$/.test(usernameOrServiceProvider) ||
            /.*?@hotmail\.com$/.test(usernameOrServiceProvider)
        )
            return protocol === 'smtp' ? 587 : 993;
        if (/.*?@btinternet\.com$/.test(usernameOrServiceProvider)) return protocol === 'smtp' ? 465 : 993;
        return current || protocol === 'smtp' ? 587 : 993;
    };

    const getDefaultTLS = (current: boolean | undefined, usernameOrServiceProvider: string, protocol: 'smtp' | 'imap'): boolean => {
        if (/.*?@yahoo\.com$/.test(usernameOrServiceProvider) || /.*?@yahoo\.co.uk$/.test(usernameOrServiceProvider)) return true;
        if (
            usernameOrServiceProvider === 'google' ||
            /.*?@gmail\.com$/.test(usernameOrServiceProvider) ||
            /.*?@googlemail\.com$/.test(usernameOrServiceProvider)
        )
            return protocol === 'smtp' ? false : true;
        if (
            usernameOrServiceProvider === 'outlook' ||
            /.*?@outlook\.com$/.test(usernameOrServiceProvider) ||
            /.*?@hotmail\.com$/.test(usernameOrServiceProvider)
        )
            return protocol === 'smtp' ? false : true;
        if (/.*?@btinternet\.com$/.test(usernameOrServiceProvider)) return true;
        return current === undefined ? (protocol === 'smtp' ? false : true) : current;
    };

    const awaitConnected = async () => {
        const url = '/api/email-engine/await-connected';
        const response = await Api.get<{ connected: boolean; error?: string }>(null, url).catch(() => undefined);

        if (!response) return Logger.error('Failed to check the connected state of the Email Engine account.'), false;

        return response.data.connected;
    };

    const verifySmtpImapSettings = async ({
        smtp,
        imap,
    }: {
        smtp?: EmailEngineSMTPConfig;
        imap?: EmailEngineIMAPConfig;
    }): Promise<boolean> => {
        setMailServerErrors(undefined);
        setVerifyingSmtpImapSettings(true);
        const existingSmtp = mailServerSettings.smtp && copyObject(mailServerSettings.smtp);
        if (existingSmtp) delete existingSmtp.valid;

        const existingImap = mailServerSettings.imap && copyObject(mailServerSettings.imap);
        if (existingImap) delete existingImap.valid;

        const response = await Api.post<EmailEngineAccountVerificationResponse | EmailEngineRequestError>(
            null,
            '/api/email-engine/verify-account',
            {
                smtp: smtp || existingSmtp,
                imap: imap || existingImap,
            }
        ).then(res => res?.data);

        let isValid = true;
        if (!response || typeof response?.error === 'string') {
            enqueueSnackbar('Failed to verify SMTP settings', { variant: 'error' });
            setConfigErrors(response?.fields || []);
            setVerifyingSmtpImapSettings(false);

            isValid = false;
            return isValid;
        }

        if (smtp) {
            if (response.smtp?.success) {
                await Api.post(null, '/api/email-engine/account', { smtp });
                setMailServerSettings({ ...mailServerSettings, smtp: { ...smtp, valid: true } });
                enqueueSnackbar('SMTP settings verified', { variant: 'success' });
            } else {
                if (response.smtp?.error) setConfigErrors([{ message: response.smtp?.error, key: '' }]);
                setMailServerSettings({ ...mailServerSettings, smtp: { ...smtp, valid: false } });
                isValid = false;
                setMailServerErrors({ smtp: response.smtp?.responseText });
                enqueueSnackbar('Failed to verify SMTP settings', { variant: 'error' });
            }
        }

        if (imap) {
            if (response.imap?.success) {
                await Api.post(null, '/api/email-engine/account', { imap });
                setMailServerSettings({ ...mailServerSettings, imap: { ...imap, valid: true } });
                enqueueSnackbar('IMAP settings verified', { variant: 'success' });
            } else {
                if (response.imap?.error) setConfigErrors([{ message: response.imap?.error, key: '' }]);
                setMailServerSettings({ ...mailServerSettings, imap: { ...imap, valid: false } });
                isValid = false;
                setMailServerErrors({ imap: response.imap?.responseText });
                enqueueSnackbar('Failed to verify IMAP settings', { variant: 'error' });
            }
        }

        if (response.smtp?.success && response.imap?.success) {
            ApplicationState.account.emailAPI = 'email-engine';
            await ApplicationState.save();
            await awaitConnected();
            await getAccount();
            enqueueSnackbar('All account settings verified, two way email enabled.', { variant: 'success' });
        }

        setVerifyingSmtpImapSettings(false);

        return isValid;
    };

    const removeConfigError = (key: string) => {
        const newErrors = configErrors.filter(x => x.key !== key);
        setConfigErrors(newErrors);
    };

    return (
        <MailServerContext.Provider
            value={{
                cacheMxService,
                configErrors,
                mailServerErrors,
                removeConfigError,
                mailServerSettings,
                setMailServerSettings,
                verifySmtpImapSettings,
                verifyingSmtpImapSettings,
                getDefaultHost,
                getDefaultPort,
                getDefaultTLS,
            }}
        >
            {children}
        </MailServerContext.Provider>
    );
};

export const useMailServer = () => {
    const context = useContext(MailServerContext);
    if (context === undefined) {
        throw new Error('useMailServer must be used within an MailServerProvider');
    }
    return context;
};
