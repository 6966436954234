import { Logger } from '../../../Logger';

export const getMxService = async (domain: string) => {
    if (['googlemail.com', 'google.com', 'gmail.com'].includes(domain)) return 'google';
    if (['outlook.com', 'office365.com', 'hotmail.com'].includes(domain)) return 'outlook';

    try {
        const response = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);

        if (!response.ok) throw new Error(`Failed to fetch MX records for ${domain}`);

        const data = (await response.json()) as { Answer: Array<{ data: string }> };

        const services = {
            google: ['google.com'],
            outlook: ['outlook.com', 'office365.com', 'hotmail.com'],
        } as const;

        for (const key in services) {
            const service = key as keyof typeof services;
            const domains = services[service];
            if (!domains?.length) continue;

            for (const domain of domains) {
                for (const answer of data.Answer) {
                    if (answer.data.toLowerCase().includes(domain)) return service;
                }
            }
        }
    } catch (error) {
        Logger.error('Failed to fetch MX records', error);
    }
};
